define("discourse/plugins/chat/discourse/components/chat-thread-heading", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/helpers/replace-emoji", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _replaceEmoji, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadHeading extends _component.default {
    get showHeading() {
      return this.args.thread?.title;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showHeading}}
          <div class="chat-thread__heading">
            <div class="chat-thread__heading-icon">
              {{icon "discourse-threads"}}
            </div>
            <h2 class="chat-thread__heading-title">
              {{replaceEmoji @thread.title}}
            </h2>
          </div>
        {{/if}}
      
    */
    {
      "id": "/j0PPgvF",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showHeading\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-thread__heading\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-thread__heading-icon\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse-threads\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"chat-thread__heading-title\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"title\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@thread\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-thread-heading.js",
      "scope": () => [_dIcon.default, _replaceEmoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatThreadHeading;
});