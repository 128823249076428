define("discourse/plugins/chat/discourse/components/channel-name/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "@ember/template", "discourse/components/plugin-outlet", "discourse/components/user-status-message", "discourse/helpers/replace-emoji", "discourse/plugins/chat/discourse/components/chat-channel-unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _template, _pluginOutlet, _userStatusMessage, _replaceEmoji, _chatChannelUnreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelName extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get unreadIndicator() {
      return this.args.unreadIndicator ?? false;
    }
    get users() {
      return this.args.channel.chatable.users;
    }
    get prefersName() {
      return this.siteSettings.enable_names && this.siteSettings.display_name_on_posts && !this.siteSettings.prioritize_username_in_ux;
    }
    get directMessageTitle() {
      if (this.users.length === 0) {
        return this.prefersName ? this.currentUser.name || this.currentUser.username : this.currentUser.username;
      }
      return this.prefersName ? this.users.map(user => user.name || user.username).join(", ") : this.users.mapBy("username").join(", ");
    }
    get channelColorStyle() {
      return (0, _template.htmlSafe)(`color: #${this.args.channel.chatable.color}`);
    }
    get showUserStatus() {
      if (!this.args.channel.isDirectMessageChannel) {
        return false;
      }
      return !!(this.users.length === 1 && this.users[0].status);
    }
    get channelTitle() {
      if (this.args.channel.isDirectMessageChannel) {
        return this.args.channel.title ?? this.directMessageTitle;
      }
      return this.args.channel.title;
    }
    get showPluginOutlet() {
      return this.args.channel.isDirectMessageChannel && !this.args.channel.chatable.group;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-channel-name">
          <div class="chat-channel-name__label">
            {{replaceEmoji this.channelTitle}}
    
            {{#if this.showUserStatus}}
              <UserStatusMessage
                @status={{get this.users "0.status"}}
                @showDescription={{if this.site.mobileView "true"}}
                class="chat-channel__user-status-message"
              />
            {{/if}}
    
            {{#if this.showPluginOutlet}}
              <PluginOutlet
                @name="after-chat-channel-username"
                @outletArgs={{hash user=@user}}
                @tagName=""
                @connectorTagName=""
              />
            {{/if}}
    
            {{#if (has-block)}}
              {{yield}}
            {{/if}}
          </div>
    
          {{#if this.unreadIndicator}}
            <ChatChannelUnreadIndicator @channel={{@channel}} />
          {{/if}}
        </div>
      
    */
    {
      "id": "sqlGuFfC",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-channel-name\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-channel-name__label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[30,0,[\"channelTitle\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"showUserStatus\"]],[[[1,\"          \"],[8,[32,1],[[24,0,\"chat-channel__user-status-message\"]],[[\"@status\",\"@showDescription\"],[[28,[32,2],[[30,0,[\"users\"]],\"0.status\"],null],[52,[30,0,[\"site\",\"mobileView\"]],\"true\"]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showPluginOutlet\"]],[[[1,\"          \"],[8,[32,3],null,[[\"@name\",\"@outletArgs\",\"@tagName\",\"@connectorTagName\"],[\"after-chat-channel-username\",[28,[32,4],null,[[\"user\"],[[30,1]]]],\"\",\"\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"          \"],[18,3,null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"unreadIndicator\"]],[[[1,\"        \"],[8,[32,5],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\",\"@channel\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/channel-name/index.js",
      "scope": () => [_replaceEmoji.default, _userStatusMessage.default, _helper.get, _pluginOutlet.default, _helper.hash, _chatChannelUnreadIndicator.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelName;
});