define("discourse/plugins/chat/discourse/components/chat/navbar/close-drawer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarCloseDrawerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    closeDrawer() {
      this.chatStateManager.didCloseDrawer();
      this.chat.activeChannel = null;
    }
    static #_3 = (() => dt7948.n(this.prototype, "closeDrawer", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon="xmark"
          @action={{this.closeDrawer}}
          @title="chat.close"
          class="btn-transparent no-text c-navbar__close-drawer-button"
        />
      
    */
    {
      "id": "FvbkB36o",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-transparent no-text c-navbar__close-drawer-button\"]],[[\"@icon\",\"@action\",\"@title\"],[\"xmark\",[30,0,[\"closeDrawer\"]],\"chat.close\"]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/close-drawer-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarCloseDrawerButton;
});