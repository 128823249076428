define("discourse/plugins/chat/discourse/components/chat/message-creator/member", ["exports", "@ember/helper", "truth-helpers", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _truthHelpers, _dButton, _concatClass, _dIcon, _chatUserAvatar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Member = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      class={{concatClass
        "chat-message-creator__member btn-default"
        (if @highlighted "-highlighted")
      }}
      @action={{fn @onSelect @member}}
    >
      {{#if (eq @member.type "user")}}
        <ChatUserAvatar
          @user={{@member.model}}
          @interactive={{false}}
          @showPresence={{false}}
        />
        <span class="chat-message-creator__member-username">
          {{@member.model.username}}
        </span>
      {{else if (eq @member.type "group")}}
        <div class="chat-message-creator__group-icon">
          {{icon "user-group"}}
        </div>
        <span class="chat-message-creator__member-group">
          {{@member.model.name}}
        </span>
      {{/if}}
  
      {{icon "xmark"}}
    </DButton>
  
  */
  {
    "id": "ZmAk30Tu",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,0,[28,[32,1],[\"chat-message-creator__member btn-default\",[52,[30,1],\"-highlighted\"]],null]]],[[\"@action\"],[[28,[32,2],[[30,2],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[32,3],[[30,3,[\"type\"]],\"user\"],null],[[[1,\"      \"],[8,[32,4],null,[[\"@user\",\"@interactive\",\"@showPresence\"],[[30,3,[\"model\"]],false,false]],null],[1,\"\\n      \"],[10,1],[14,0,\"chat-message-creator__member-username\"],[12],[1,\"\\n        \"],[1,[30,3,[\"model\",\"username\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,3],[[30,3,[\"type\"]],\"group\"],null],[[[1,\"      \"],[10,0],[14,0,\"chat-message-creator__group-icon\"],[12],[1,\"\\n        \"],[1,[28,[32,5],[\"user-group\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"chat-message-creator__member-group\"],[12],[1,\"\\n        \"],[1,[30,3,[\"model\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"\\n    \"],[1,[28,[32,5],[\"xmark\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@highlighted\",\"@onSelect\",\"@member\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/member.js",
    "scope": () => [_dButton.default, _concatClass.default, _helper.fn, _truthHelpers.eq, _chatUserAvatar.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "member:Member"));
  var _default = _exports.default = Member;
});