define("discourse/plugins/chat/discourse/components/chat/form/section", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat/form/row", "@ember/component", "@ember/template-factory"], function (_exports, _component, _row, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatFormSection extends _component.default {
    get yieldableArgs() {
      return {
        row: _row.default
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-form__section" ...attributes>
          {{#if @title}}
            <div class="chat-form__section-title">
              {{@title}}
            </div>
          {{/if}}
    
          <div class="chat-form__section-content">
            {{yield this.yieldableArgs}}
          </div>
        </div>
      
    */
    {
      "id": "RkkNZo/J",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"chat-form__section\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"chat-form__section-title\"],[12],[1,\"\\n          \"],[1,[30,2]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"chat-form__section-content\"],[12],[1,\"\\n        \"],[18,3,[[30,0,[\"yieldableArgs\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@title\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/form/section.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatFormSection;
});