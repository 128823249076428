define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _navbar, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    get backBtnRoute() {
      if (this.chatHistory.previousRoute?.name === "chat.browse") {
        return "chat.browse";
      } else if (this.args.model?.channel?.isDirectMessageChannel) {
        return "chat.direct-messages";
      } else {
        return "chat.channels";
      }
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --channel">
          {{#if @model.channel}}
            <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
              <navbar.BackButton @route={{this.backBtnRoute}} />
              <navbar.ChannelTitle @channel={{@model.channel}} />
              <navbar.Actions as |a|>
                <a.ThreadsListButton @channel={{@model.channel}} />
                <a.ToggleDrawerButton />
                <a.FullPageButton />
                <a.CloseDrawerButton />
              </navbar.Actions>
            </Navbar>
    
            {{#if this.chatStateManager.isDrawerExpanded}}
              <div class="chat-drawer-content">
                {{#each (array @model.channel) as |channel|}}
                  <ChatChannel
                    @targetMessageId={{readonly @params.messageId}}
                    @channel={{channel}}
                  />
                {{/each}}
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "SnkG+00C",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --channel\"],[12],[1,\"\\n\"],[41,[30,1,[\"channel\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BackButton\"]],null,[[\"@route\"],[[30,0,[\"backBtnRoute\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n            \"],[8,[30,3,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"FullPageButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[28,[32,1],[[30,1,[\"channel\"]]],null]],null]],null],null,[[[1,\"              \"],[8,[32,2],null,[[\"@targetMessageId\",\"@channel\"],[[28,[31,3],[[30,5,[\"messageId\"]]],null],[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"navbar\",\"a\",\"channel\",\"@params\"],false,[\"if\",\"each\",\"-track-array\",\"readonly\"]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel.js",
      "scope": () => [_navbar.default, _helper.array, _chatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannel;
});