define("discourse/plugins/chat/discourse/components/chat/message-creator/group", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatableGroup extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    group_with_too_many_members = (() => (0, _discourseI18n.i18n)("chat.new_message_modal.group_with_too_many_members", {
      membersCount: this.args.item.model.chat_enabled_user_count
    }))();
    get isDisabled() {
      if (!this.args.membersCount) {
        return !this.args.item.enabled;
      }
      return this.args.membersCount + this.args.item.model.chat_enabled_user_count > this.siteSettings.chat_max_direct_message_users;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="chat-message-creator__chatable -group"
          data-disabled={{this.isDisabled}}
        >
          <div class="chat-message-creator__group-icon">
            {{icon "user-group"}}
          </div>
          <div class="chat-message-creator__group-name">
            {{@item.model.name}}
          </div>
    
          {{#if this.isDisabled}}
            <span class="chat-message-creator__disabled-warning">
              {{this.group_with_too_many_members}}
            </span>
          {{/if}}
        </div>
      
    */
    {
      "id": "iGUBJlZ3",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -group\"],[15,\"data-disabled\",[30,0,[\"isDisabled\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__group-icon\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user-group\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__group-name\"],[12],[1,\"\\n        \"],[1,[30,1,[\"model\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isDisabled\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-creator__disabled-warning\"],[12],[1,\"\\n          \"],[1,[30,0,[\"group_with_too_many_members\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/group.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatableGroup;
});